import WalletConnectProvider from "@walletconnect/web3-provider"
import { ethers } from "ethers"

import nftAbi from './abis/nft.json'


export {nftAbi}
export const n43113= {
    deployerContract: '0xa5c2565C3610ffd63A67b701011d2C2d6b6b227e',
    Provider: window.ethereum || new WalletConnectProvider({ rpc: { 43113: 'https://api.avax-test.network/ext/bc/C/rpc' } }),
    Signer: new ethers.providers.Web3Provider(window.ethereum || new WalletConnectProvider({ rpc: { 43113: 'https://api.avax-test.network/ext/bc/C/rpc' } })).getSigner(),
}
export const n43114 = {
    deployerContract: '0x07aA8E7888987109051a71802e9b6DA5B942de9D',
    Provider: window.ethereum || new WalletConnectProvider({ rpc: { 43114: 'https://api.avax.network/ext/bc/C/rpc' } }),
    Signer: new ethers.providers.Web3Provider(window.ethereum || new WalletConnectProvider({ rpc: { 43114: 'https://api.avax.network/ext/bc/C/rpc' } })).getSigner(),
}
export const n1 = {
    deployerContract: '',
    Provider: window.ethereum || new WalletConnectProvider({ rpc: { 1: 'https://mainnet.infura.io/v3/' } }),
    Signer: new ethers.providers.Web3Provider(window.ethereum || new WalletConnectProvider({ rpc: { 1: 'https://mainnet.infura.io/v3/' } })).getSigner(),
}
export function changeNetwork(chainId) {
    const provider = window.ethereum || new WalletConnectProvider({ rpc: { 43113: 'https://api.avax-test.network/ext/bc/C/rpc' } })
    return provider?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
    }).catch((error) => {
        if (error.code === 4902) {
            if(chainId === 1) {
                return provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        chainId: '0x1',
                        chainName: 'Ethereum Mainnet',
                        nativeCurrency: {
                            name: 'ETH',
                            symbol: 'eth',
                            decimals: 18,
                        },
                        rpcUrls: ['https://mainnet.infura.io/v3/'],
                        blockExplorerUrls: ['https://etherscan.io'],
                    }],
                })
            } else if(chainId === 43114) {
                return provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        chainId: '0xa86a',
                        chainName: 'Avalanche Mainnet C-Chain',
                        nativeCurrency: {
                            name: 'AVAX',
                            symbol: 'AVAX',
                            decimals: 18,
                        },
                        rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
                        blockExplorerUrls: ['https://snowtrace.io/'],
                    }],
                })
            } else if(chainId === 43113) {
                return provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        chainId: '0xa869',
                        chainName: 'Avalanche Fuji C-Chain',
                        nativeCurrency: {
                            name: 'AVAX',
                            symbol: 'AVAX',
                            decimals: 18,
                        },
                        rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
                        blockExplorerUrls: ['https://testnet.snowtrace.io/'],
                    }],
                })
            } 
                
        }
    })

}