import { Alert, background, Box, Button, Grid, GridItem, Heading, HStack, Image, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Spinner, Text, useColorMode, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import * as constants from "../constants"
import transferAbi from "../abis/transfer.json"
import nftAbi from "../abis/nft.json"
import ashAbi from "../abis/ashes.json"
import Moralis from "moralis";
import { EvmChain } from "@moralisweb3/common-evm-utils";
import { ChevronDownIcon } from "@chakra-ui/icons";
const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Home() {


    const [user, setUser] = useState()
    const [ens, setEns] = useState()
    const [nothing, doNothing] = useState(0)
    const [nfts, setNfts] = useState()
    const [to, setTo] = useState()
    const [from, setFrom] = useState()
    const [selected, setSelected] = useState()
    const [trait, setTrait] = useState()
    const [rerollTrait, setRerollTrait] = useState()
    const [traitName, setTraitName] = useState()
    const [imgString, setImgString] = useState()
    const [approved, setApproved] = useState(false)
    const [ashesApproved, setAshesApproved] = useState(false)
    const [ashesBalance, setAshesBalance] = useState()
    const [amountToBuy, setAmountToBuy] = useState(1)

    const ethProvider = useMemo(() => new ethers.providers.JsonRpcProvider('https://mainnet.infura.io/v3/603aa98cb2a04214a05afd3b40cd2322'), [])
    const provider = constants.n43114.Provider
    const signer = useMemo(() => new ethers.providers.Web3Provider(provider).getSigner(), [])
    const contract = useMemo(() => new ethers.Contract('0xF95107CFF7b45F672a8869035DAE596aC9e4F379', transferAbi, signer), [])
    const nftContract = useMemo(() => new ethers.Contract('0x03eD65272eb935F3937e1275deAa63bc3ecd1C27', nftAbi, signer), [])
    const ashesContract = useMemo(() => new ethers.Contract('0x8b8F8173D36F693ffBa997BFa961146C5C9037D0', ashAbi, signer), [])
    const toast = useToast()
    const colorMode = useColorMode()


    useEffect(() => {
        signer.getAddress().then((address) => {
            address ? setUser(address) : doNothing(null)
        })
        user ? ethProvider.lookupAddress(user).then((ens) => {
            setEns(ens)
        }) : doNothing(null)
        constants.changeNetwork(43114)
        colorMode.setColorMode("dark")
        nftContract.isApprovedForAll(user, "0xF95107CFF7b45F672a8869035DAE596aC9e4F379").then((e) => {
            setApproved(e)
        })
        ashesContract.isApprovedForAll(user, "0xF95107CFF7b45F672a8869035DAE596aC9e4F379").then((e) => {
            setAshesApproved(e)
        })
        ashesContract.balanceOf(user, 0).then((e) => {
            setAshesBalance(e.toString())
        })

    }, [ashesContract, colorMode, contract, doNothing, ethProvider, nftContract, signer, user])

    useMemo(() => {
        user ? fetch("https://deep-index.moralis.io/api/v2/" + user + "/nft?chain=0xa86a&token_addresses=0x03eD65272eb935F3937e1275deAa63bc3ecd1C27", { method: "GET", headers: { accept: 'application/json', 'X-API-Key': "rTO3ivS5kbrUYLADjiK07aprt07UIKh9lcdEy346azAzfSGi6zfYLBA4z9C1MuE4" } }).then((nfts) => {
            nfts.json().then(e => setNfts(e.result))
        }) : doNothing(null)
    }, [user])

    console.log(nfts)

    const connect = async () => {
        try {
            await provider.enable()
        } catch (err) {
            console.log(err)
        }
        try {
            await provider.request({
                method: "wallet_requestPermissions",
                params: [{
                    eth_accounts: {}
                }]
            })
            await provider.request({ method: 'eth_requestAccounts' });
        } catch (err) {
            console.log(err)
        }
        window.location.reload()
    }
    const { isOpen, onOpen, onClose } = useDisclosure()
    const transfer = async () => {
        const tx = await contract.transferTrait(from, to, trait).catch((err) => {
            console.log(err)
            toast({
                title: "Error",
                description: err.reason || err.message || err,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        })
        await tx.wait()
        toast({
            title: "Success",
            description: "Trait transfered",
            status: "success",
            duration: 9000,
            isClosable: true,
        })

        setTimeout(async() => {
        await fetch("https://giga-bone-server-mrsvznbjfq-uc.a.run.app/images/" + to + ".jpg", {method: "GET"}).then((res) => res.blob().then((e) =>setImgString( URL.createObjectURL(e))))
        }, 3000)
        onOpen()
    }

    const approve = async () => {
        const tx = await nftContract.setApprovalForAll("0xF95107CFF7b45F672a8869035DAE596aC9e4F379", true).catch((err) => {
            console.log(err)
            toast({
                title: "Error",
                description: err.reason || err.message || err,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        })
        await tx.wait()
        toast({
            title: "Success",
            description: "Approved",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
        setApproved(true)
    }

    const ashesApprove = async () => {
        const tx = await ashesContract.setApprovalForAll("0xF95107CFF7b45F672a8869035DAE596aC9e4F379", true).catch((err) => {
            console.log(err)
            toast({
                title: "Error",
                description: err.reason || err.message || err,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        })
        await tx.wait()
        toast({
            title: "Success",
            description: "Approved",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
        setAshesApproved(true)
    }

    const reroll = async () => {
        const tx = await contract.rerollTrait(selected, trait).catch((err) => {
            console.log(err)
            toast({
                title: "Error",
                description: err.reason || err.message || err,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        })
        await tx.wait()
        toast({
            title: "Success",
            description: "Trait rerolled",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    const buyAshes = async () => {
        const tx = await contract.buyAshes(amountToBuy, {value: ethers.utils.parseEther((amountToBuy * 0.1).toString())}).catch((err) => {
            console.log(err)
            toast({
                title: "Error",
                description: err.reason || err.message || err,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        })
        await tx.wait()
        toast({
            title: "Success",
            description: "Ashes bought",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }

    

    console.log(from, to)

    return (
        <>
            <Box>
                <VStack spacing={4} textAlign='center'>
                    <Spacer/>
                    <Button onClick={connect}>{user?shorten(user):"connect"}</Button>
                    <Heading>Trait transfer</Heading>
                    <Text>temporarily disabled</Text>
                    
                    {/* <HStack>
                        <VStack>
                            <Image minH={'100px'} maxH={"100px"} src={"https://giga-bone-server-mrsvznbjfq-uc.a.run.app/images/" + from + ".jpg"} alt="from token" />
                            <Menu>
                                <MenuButton as={Button}>from token {from || ""}<ChevronDownIcon /></MenuButton>
                                <MenuList>
                                    {nfts?.map((nft, i) => {
                                        return (
                                            <MenuItem key={i} onClick={() => setFrom(nft.token_id)}>
                                                <Box key={i} >
                                                    <Text>{JSON.parse(nft.metadata).name}</Text>
                                                    <Image maxHeight={'50px'} src={JSON.parse(nft.metadata).image} />
                                                </Box>
                                            </MenuItem>
                                        )
                                    })}
                                </MenuList>
                            </Menu>
                        </VStack>
                        <VStack>
                            <Image minH={'100px'} maxH={"100px"} src={"https://giga-bone-server-mrsvznbjfq-uc.a.run.app/images/" + to + ".jpg"} alt="to token" />
                            <Menu>
                                <MenuButton as={Button}>to token {to || ""}<ChevronDownIcon /></MenuButton>
                                <MenuList>
                                    {nfts?.map((nft, i) => {
                                        return (
                                            <MenuItem key={i} onClick={() => setTo(nft.token_id)}>
                                                <Box key={i} >
                                                    <Text>{JSON.parse(nft.metadata).name}</Text>
                                                    <Image maxHeight={'50px'} src={JSON.parse(nft.metadata).image} />
                                                </Box>
                                            </MenuItem>
                                        )
                                    })}
                                </MenuList>
                            </Menu>
                        </VStack>
                    </HStack>
                    <Menu>
                        <MenuButton as={Button}>transfer {traitName || 'trait'}<ChevronDownIcon /> </MenuButton>
                        <MenuList>
                            <MenuItem onClick={() => {
                                setTrait(0);
                                setTraitName('background')
                            }}>background</MenuItem>
                            <MenuItem onClick={() => {
                                setTrait(1)
                                setTraitName('extra')
                            }}>extra</MenuItem>
                            <MenuItem onClick={() => {
                                setTrait(2)
                                setTraitName('body')
                            }}>body</MenuItem>
                            <MenuItem onClick={() => {
                                setTrait(3)
                                setTraitName('skull')
                            }}>skull</MenuItem>
                            <MenuItem onClick={() => {
                                setTrait(4)
                                setTraitName('neck')
                            }}>neck</MenuItem>
                            <MenuItem onClick={() => {
                                setTrait(5)
                                setTraitName('mouth')
                            }}>mouth</MenuItem>
                            <MenuItem onClick={() => {
                                setTrait(6)
                                setTraitName('eyes')
                            }}>eyes</MenuItem>
                            <MenuItem onClick={() => {
                                setTrait(7)
                                setTraitName('head accessory')
                            }}>head accessory</MenuItem>
                        </MenuList>
                    </Menu>
                    <Alert>Transfers BURN the NFT a trait is transferred from and give you 3 bone ash.</Alert>
                    <Button disabled onClick={()=>approved?transfer():approve()}>{approved?"Transfer":"approve transfer contract"}</Button>
                    <Spacer/> */}
                    <Heading>Trait reroll</Heading>
                    <Text>temporarily disabled</Text>
                    {/* <Text>Owned ashes = {ashesBalance}</Text>
                    <Box>
                        <Image minH={'100px'} maxH={"150px"} src={"https://giga-bone-server-mrsvznbjfq-uc.a.run.app/images/" + selected + ".jpg"} alt="reroll token" />
                        <Menu>
                            <MenuButton as={Button}>reroll token {selected || ""}<ChevronDownIcon /></MenuButton>
                            <MenuList>
                                {nfts?.map((nft, i) => {
                                    return (
                                        <MenuItem key={i} onClick={() => setSelected(nft.token_id)}>
                                            <Box key={i} >
                                                <Text>{JSON.parse(nft.metadata).name}</Text>
                                                <Image maxHeight={'50px'} src={JSON.parse(nft.metadata).image} />
                                            </Box>
                                        </MenuItem>
                                    )
                                })}
                            </MenuList>
                        </Menu>
                        <br/>
                        <Menu>
                            <MenuButton as={Button}>reroll {traitName || 'trait'}<ChevronDownIcon /> </MenuButton>
                            <MenuList>
                                <MenuItem onClick={() => {
                                    setTrait(0);
                                    setTraitName('background')
                                }}>background</MenuItem>
                                <MenuItem onClick={() => {
                                    setTrait(1)
                                    setTraitName('extra')
                                }}>extra</MenuItem>
                                <MenuItem onClick={() => {
                                    setTrait(2)
                                    setTraitName('body')
                                }}>body</MenuItem>
                                <MenuItem onClick={() => {
                                    setTrait(3)
                                    setTraitName('skull')
                                }}>skull</MenuItem>
                                <MenuItem onClick={() => {
                                    setTrait(4)
                                    setTraitName('neck')
                                }}>neck</MenuItem>
                                <MenuItem onClick={() => {
                                    setTrait(5)
                                    setTraitName('mouth')
                                }}>mouth</MenuItem>
                                <MenuItem onClick={() => {
                                    setTrait(6)
                                    setTraitName('eyes')
                                }}>eyes</MenuItem>
                                <MenuItem onClick={() => {
                                    setTrait(7)
                                    setTraitName('head accessory')
                                }}>head accessory</MenuItem>
                            </MenuList>
                        </Menu>
                        <br/>
                        <Alert>rerolling a trait costs 1 ashes</Alert>
                        <Button onClick={()=>ashesApproved?reroll():ashesApprove()}>{ashesApproved?'reroll':'approve ashes'}</Button>
                        <Input type={"number"} placeholder="amount to buy" onChange={(e)=>setAmountToBuy(e.target.value)}/>
                        <Button onClick={()=> buyAshes()}>buy {amountToBuy} ashes for {(amountToBuy * (0.1*100))/100} avax</Button>
                    </Box>
                    <Box rounded={'lg'} p={8} border={'1px'} borderColor={'gray.200'}>
                        <Heading>Your owned GIGA Bones</Heading>
                        <SimpleGrid minChildWidth={'300px'} spacing={10}>
                            {nfts?.map((nft, i) => {
                                return (
                                    <Box rounded={'lg'} border='1px' p='1' mt='3' key={i} >
                                        <Text>{JSON.parse(nft.metadata).name}</Text>
                                        <Image rounded={'lg'} src={JSON.parse(nft.metadata).image} />
                                    </Box>
                                )
                            })}
                        </SimpleGrid>
                    </Box> */}
                </VStack>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Trait transferred!</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text>GIGA Bone #{to}</Text>
                            <Image src={imgString} alt="loading image"/>
                            <Alert>Sometimes the old image shows up, just wait a few seconds and refresh the page to see the new image</Alert>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={()=>window.location.reload()}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Box>
        </>
    )
}